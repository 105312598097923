import React from 'react'
import tw from 'twin.macro'
import FadeInTitle from '../../common/FadeInTitle'
import StaggeredFade from '../../common/StaggeredFade'
import { BigBlue, Content, Email, GradientTitle, List, Title3 } from '../../common/Typography'

const Container = tw.div`
  my-32 min-h-screen px-5 container mx-auto
`

const WelcomeParagraph = tw(Content)`
  mt-10 px-8 py-4 bg-neutral-900 rounded-md border border-neutral-800
`

const Section = tw.div`
  mt-8
`

export default function PrivacyPolicy(props) {
  return (
    <Container>
      <FadeInTitle content={'Privacy is why we\'re here.'} />
      <WelcomeParagraph>
        <StaggeredFade>
          <BigBlue>Your privacy is critically important to us. At SAIK we have a few fundamental principles:</BigBlue>
          <List>
            <li>We don't ask you for personal information unless we truly need it. (We can't stand services that ask you for things like your gender or income level for no apparent reason.)</li>
            <li>We don't share your personal information with anyone except to comply with the law, develop our products, or protect our rights.</li>
            <li>We don't store personal information on our servers unless required for the on-going operation of one of our services. </li>
          </List>
          <Content>
            Below is our privacy policy which incorporates these goals. If you have questions about
            deleting or correcting your personal data please contact us at <Email href="mailto:info@saik.ai">info@saik.ai</Email>.
            SAIK Inc. (“SAIK”) operates saik.ai. It is SAIK's policy to respect your privacy
            regarding any information we may collect while operating our websites.
          </Content>
        </StaggeredFade>
      </WelcomeParagraph>
      <GradientTitle className="mt-14">Privacy Policy</GradientTitle>
      <Section>
        <Title3>Website Visitors</Title3>
        <Content>
          Like most website operators, SAIK collects non-personally-identifying information of the
          sort that web browsers and servers typically make available, such as the browser type,
          language preference, referring site, and the date and time of each visitor request. SAIK's
          purpose in collecting non-personally identifying information is to better understand how
          SAIK's visitors use its website. From time to time, SAIK may release non-personally-identifying
          information in the aggregate, e.g., by publishing a report on trends in the
          usage of its website.
        </Content>
      </Section>
      <Section>
        <Title3>Gathering of Personally-Identifying Information</Title3>
        <Content>
          Certain visitors to SAIK's websites choose to interact with SAIK in ways that require
          SAIK to gather personally-identifying information. The amount and type of information
          that SAIK gathers depends on the nature of the interaction. For example, we ask visitors
          who sign up for a mailing list to provide a username and email address. SAIK does not
          disclose personally-identifying information other than as described below. Visitors
          can always refuse to supply personally-identifying information, with the caveat that it
          may prevent them from engaging in certain website-related activities.
        </Content>
      </Section>
      <Section>
        <Title3>Cookies</Title3>
        <Content>
        A cookie is a string of information that a website stores on a visitor's computer, and that
        the visitor's browser provides to the website each time the visitor returns. SAIK uses
        cookies to help SAIK identify and track visitors, their usage of the SAIK website, and their
        website access preferences. SAIK visitors who do not wish to have cookies placed on
        their computers should set their browsers to refuse cookies before using SAIK's websites,
        with the drawback that certain features of SAIK's websites may not function properly
        without the aid of cookies.
        </Content>
      </Section>
      <Section>
        <Title3>Business Transfers</Title3>
        <Content>
        If SAIK, or substantially all of its assets, were acquired, or in the unlikely event that
        SAIK goes out of business or enters bankruptcy, user information would be one of the
        assets that is transferred or acquired by a third party. You acknowledge that such
        transfers may occur, and that any acquirer of SAIK may continue to use your personal
        information as set forth in this policy.
        </Content>
      </Section>
      <Section>
        <Title3>Privacy Policy Changes</Title3>
        <Content>
        Although most changes are likely to be minor, SAIK may change its Privacy Policy from
        time to time, and in SAIK's sole discretion. SAIK encourages visitors to frequently check
        this page for any changes to its Privacy Policy.
        </Content>
      </Section>
    </Container>
  )
}
