import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { ReactComponent as SAIK } from '../../svg/SAIK.svg'
import SAIKAnimated from '../../svg/animated/SAIKAnimated'

const StyledWordmark = styled(SAIK)(({ fillcolor }) => [
  tw` fill-white`,
  fillcolor && css`
    fill: ${fillcolor};
  `
])

const StyledAnimatedWordmark = styled(SAIKAnimated)(({ fillcolor }) => [
  tw` fill-white`,
  fillcolor && css`
    fill: ${fillcolor};
  `
])

export const AnimatedWordmark = React.forwardRef(({ fillcolor, ...others }, forwardRef) => {
  return (
    <StyledAnimatedWordmark fillcolor={fillcolor} {...others} ref={forwardRef} />
  )
})

const Wordmark = React.forwardRef(({ fillcolor, ...others }, forwardRef) => {
  return (
    <StyledWordmark fillcolor={fillcolor} {...others} ref={forwardRef} />
  )
})

export default Wordmark

Wordmark.displayName = 'Wordmark'

Wordmark.propTypes = {
  fillcolor: PropTypes.string
}
