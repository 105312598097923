import { KeyRound, Shield, Slice } from 'lucide-react'
import React from 'react'
import tw from 'twin.macro'
import Carousel from '../common/Carousel'
import FadeInTitle from '../common/FadeInTitle'
import { ScrollAnchor } from '../common/ScrollAnchor'
import { BigBlue } from '../common/Typography'

const Container = tw.div`
  my-40
  mx-5
  sm:mx-10
  lg:mx-[220px]
`

const CONTENT = [
  {
    'title': 'Trustworthy',
    'icon': Shield,
    'content': 'Generative AI has a weakness when it comes trustworthiness. It is known to sometimes hallucinate, or confidently give a user false information. SAIK avoids this by relying on concrete information from your own documents and data. When it gives you an answer, it provides references to the information it used to answer your prompt. If your documents don\'t provide relevant information it will let you know. SAIK tells you the page numbers it referred to, enabling you to download the information and verify the facts yourself.'
  },
  {
    'title': 'Safe',
    'icon': KeyRound,
    'content': (
    <>
      Other services rely on third parties for their AI, and those third parties often keep logs of that information. 
      A security breach at the third party could leak your organization's sensitive data. SAIK can run in your own environment, <BigBlue>either on-premises or on your own cloud.</BigBlue> Organizations maintain full control over their own information.
    </>
    )
  },
  {
    'title': 'Cutting Edge',
    'icon': Slice,
    'content': 'The pace of AI Large Language Model research and development is unprecedented. To stay on the cutting edge, SAIK is designed for easy updates to its LLM as they become available. This also allows organizations to choose which LLM they use if they want a special model tuned to their domain, including support for the latest and best open-source model that meets their needs.'
  }
]

export default function Adjectives(props) {
  

  return (
    <>
      <ScrollAnchor id="howDoesSaikHelp" />
      <Container>
        <FadeInTitle content={'What makes SAIK better?'} />
        <Carousel content={CONTENT} />
      </Container>
    </>
  )
}
