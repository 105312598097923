import { motion } from 'framer-motion'
import React from 'react'
import { css } from 'styled-components'

export default function DrawingCircle(props) {
  return (
    <motion.svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="-100 -100 2367.22 1328.99" height='100%' width='100%'>
      <defs>
        <style>
          {css`
            .cls-1 {
              fill: #FFF;
            }
          `}
        </style>
      </defs>
      <motion.path
        initial={{ pathLength: 0 }} animate={{ pathLength: 1 }}
        transition={{
          duration: 3
        }}
        strokeWidth={4}
        strokeDasharray="0 1"
        className="cls-1" d="m1579.44,44c68.87,0,134.89,4.21,196.22,12.5,60.02,8.12,115.91,20.19,166.12,35.9,49.25,15.4,93.22,34.34,130.7,56.3,36.64,21.47,67.17,45.88,90.76,72.56,23.22,26.26,39.9,55.01,49.59,85.44,9.92,31.16,12.74,64.86,8.38,100.16-4.16,33.68-14.96,68.8-32.07,104.4-17.19,35.74-40.94,72.36-70.59,108.85-29.59,36.42-65.33,73.02-106.21,108.8-40.77,35.68-87,70.84-137.41,104.5-102.35,68.35-219.73,129.57-348.88,181.97-132.96,53.95-272.51,96.1-414.8,125.28-143.33,29.4-283.05,44.31-415.27,44.31-91.89,0-178.17-7.3-256.45-21.7-76.99-14.16-145.07-34.97-202.34-61.84-56.26-26.39-101.13-58.19-133.38-94.52-31.67-35.67-50.97-75.77-57.36-119.18-4.71-32.03-2.56-66.02,6.41-101.04,9.1-35.52,25.28-72.49,48.08-109.88,23.08-37.85,53.06-76.31,89.11-114.33,36.37-38.36,79.1-76.45,127-113.21,48.48-37.21,102.55-73.28,160.71-107.22,59.23-34.56,123.16-67.17,190.02-96.92,140.37-62.47,291.89-111.99,450.35-147.18,161.22-35.8,316.42-53.96,461.3-53.96h.01m-.01-44c-143.11,0-302.19,17.55-470.84,55.01C445.91,202.18-42.39,586.44,2.91,894.15c31.29,212.55,312.58,334.84,693.06,334.84,131.58,0,275.04-14.63,424.11-45.21,590.38-121.1,1106.08-458.44,1144.79-771.52C2296.21,158.85,2010.02,0,1579.43,0h0Z"
      />
    </motion.svg>
  )
}
