import React from 'react'
import tw from 'twin.macro'
import FadeInTitle from '../common/FadeInTitle'
import { ScrollAnchor } from '../common/ScrollAnchor'
import { Content, Email } from '../common/Typography'

const Container = tw.div`
  mb-40
  mx-5
  md:mx-[220px]
`

export default function Contact(props) {
  

  return (
    <>
      <ScrollAnchor id="contact" />
      <Container>
        <FadeInTitle content={'Let\'s talk about it.'} />
        <Content>Have more questions? Do you think SAIK is right for your business? You can contact us at <Email href="mailto:info@saik.ai">info@saik.ai</Email>.</Content>
      </Container>
    </>
  )
}
