import { Transition } from '@headlessui/react'
import { motion } from 'framer-motion'
import debounce from 'lodash.debounce'
import { AlertTriangle, ArrowLeft, ArrowRight } from 'lucide-react'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import tw from 'twin.macro'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { MOBILE_BREAKPOINT } from '../pages/Main'
import { Title2 } from './Typography'

// https://stackoverflow.com/a/62415754
function isTouchEvent(e) {
  if (e.wheelDeltaY) {
    if (e.wheelDeltaY === (e.deltaY * -3)) {
      return true;
    }
  }
  else if (e.deltaMode === 0) {
    return true;
  }
}

const Container = tw.div`
  relative w-full
`

const InnerCarousel = styled.div(({ $showAll }) => [
  tw`
    flex flex-row items-stretch justify-between gap-72 overflow-x-scroll h-full my-5 pb-4 px-[25%]
    snap-mandatory snap-x scroll-smooth scrollbar-thin
  `,
  $showAll && tw`
    gap-10! px-4! overflow-x-hidden!
  `
])

const CardContainer = styled.div(({ $showAll }) => [
  tw`
    relative mx-[25%] snap-center self-stretch
  `,
  $showAll && tw`mx-5`
])

const InnerCard = tw.div`
  rounded bg-neutral-900 w-[75vw] md:w-[512px] px-4 relative pb-2 h-full text-sm md:text-base leading-relaxed border border-neutral-800
`

const TitleContainer = tw(motion.div)`
  flex flex-row gap-2 z-10 items-center [&>svg]:stroke-cyan-300 rounded-md py-1 saturate-200
  
`

const GradientTitle = tw(Title2)`
  bg-gradient-to-r! from-cyan-400 to-emerald-400 text-transparent bg-clip-text pt-1.5 break-words text-2xl md:text-4xl contrast-200
`

const Icon = tw(AlertTriangle)`
  shrink-0
`

const scrollerStyles = `
  absolute bottom-0 px-2 h-[calc(100% - 16px)] w-10 
  flex items-center justify-center rounded cursor-pointer
  from-neutral-700 to-transparent transition-opacity transition-colors text-neutral-400
  z-20 mb-4
  hover:(text-white from-neutral-400!)
`

const LeftScroller = tw(Transition)`
  ${scrollerStyles}
  bg-gradient-to-r left-0 
`

const RightScroller = tw(Transition)`
  ${scrollerStyles}
  bg-gradient-to-l right-0
`

function Card ({ title, icon, content, showAll }) {

  return (
    <CardContainer $showAll={showAll}>
      <InnerCard>
        <TitleContainer>
          <Icon as={icon} height={32} width={32} />
          <GradientTitle>{title}</GradientTitle>
        </TitleContainer>
        {content}
      </InnerCard>
    </CardContainer>
  )
}

const SCROLL_AMOUNT = 1000
const SCROLL_PADDING = 200
const SCROLLER_TRANSITION = {
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0'
}

const SCREEN_PADDING = 440
const CARD_SIZE = 567

export default function Carousel({ content }) {
  const scrollRef = useRef()
  const [hasLeft, setLeft] = useState(false)
  const [hasRight, setRight] = useState(true)
  const { width } = useWindowDimensions()

  const getCardWidth = () => {
    return width < MOBILE_BREAKPOINT ? width * 0.75 : 512
  }

  const updateScrollers = (newValue) => {
    if (newValue >= getCardWidth()) {
      setLeft(true)
    } else {
      setLeft(false)
    }

    const maxScroll = scrollRef.current.scrollWidth - scrollRef.current.clientWidth

    if (newValue < maxScroll - getCardWidth()) {
      setRight(true)
    } else {
      setRight(false)
    }
  }

  const scroll = debounce((element, dir) => {
    const newScroll = element.scrollLeft + (SCROLL_AMOUNT * dir)
    element.scrollLeft = newScroll
    // updateScrollers(newScroll)
  }, 150)

  const onScroll = (e) => {
    updateScrollers(scrollRef?.current.scrollLeft)
  }

  useEffect(() => {
    const element = scrollRef?.current
    
    const handleScroll = e => {
      if (!isTouchEvent(e)) {
        const maxScroll = element.scrollWidth - element.clientWidth
        const scrollDirection = Math.sign(e.deltaY)
      // Check if we're on the boundary of the element to allow smoothly scrolling through the page
        if (!(scrollDirection === -1 && element.scrollLeft < SCROLL_PADDING) && 
            !(scrollDirection === 1 && element.scrollLeft > maxScroll - SCROLL_PADDING)) 
        {
          scroll(element, scrollDirection)
          e.preventDefault()
        }
      }
    }

    if (element) {
      element.addEventListener("wheel", handleScroll)
    }
    return () => {
      element.removeEventListener("wheel", handleScroll)
    }
  }, [scrollRef, scroll])

  useLayoutEffect(() => {
    updateScrollers()
    // This only runs when the layout first updates, so we don't need the dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <InnerCarousel ref={scrollRef} onScroll={onScroll} $showAll={(width - SCREEN_PADDING > CARD_SIZE * content?.length) ? true : undefined}>
        { content.map((card, index) => (
          <Card {...card} key={index} showAll={(width - SCREEN_PADDING > CARD_SIZE * content?.length) ? true : undefined} />
        ))}
      </InnerCarousel>
      <LeftScroller
        show={hasLeft}
        {...SCROLLER_TRANSITION}
        onClick={() => { scroll(scrollRef?.current, -1)}}>
        <ArrowLeft />
      </LeftScroller>
      <RightScroller
        show={hasRight}
        {...SCROLLER_TRANSITION}
        onClick={() => { scroll(scrollRef?.current, 1)}}>
        <ArrowRight />
      </RightScroller>
    </Container>
  )
}
