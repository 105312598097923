import React from 'react'
import tw from 'twin.macro'
import FadeInTitle from '../common/FadeInTitle'
import { ScrollAnchor } from '../common/ScrollAnchor'
import StaggeredFade from '../common/StaggeredFade'
import { Content, List } from '../common/Typography'

const Container = tw.div`
  mt-[210px] mx-5 sm:mx-10 lg:(mx-[220px] mt-[220px])
`

const WhatIsTitleContainer = tw.div`
  mb-5
`

const Info = tw.div`
  flex flex-col items-center gap-5 text-base md:text-lg
`

const PageTitle = tw(FadeInTitle)`
  relative
`

const Summary = tw.div`
  font-extrabold bg-gradient-to-r! from-cyan-400 to-emerald-400 text-transparent bg-clip-text contrast-200 text-2xl md:text-3xl mx-10 xl:mx-40
`

export default function WhatIsSAIK(props) {
  return (
    <>
      <ScrollAnchor id="whatIsSaik" />
      <Container>
      <StaggeredFade>
        <WhatIsTitleContainer>
          <PageTitle content={'Grow your business with !!!secure AI.'} />
        </WhatIsTitleContainer>
        <Info>
          <Summary>SAIK is an AI assistant built for people who need quick, accurate answers gathered from their own sensitive data.</Summary>
          <Content>
            With advanced AI capabilities and a virtual assistant that can access and analyze large archives of controlled data, SAIK makes it easy for users to find the information they need quickly and efficiently. The platform's document analysis engine can:
            <List>
              <li>summarize documents and projects, write emails, and create customized sales pitches</li>
              <li>protect confidential organizational data</li>
              <li>utilize an organization's unique knowledge and preferences to get tailored answers based on existing user permissions</li>
            </List>
          </Content>
          {/* { width > MOBILE_BREAKPOINT &&
            <FancyBrain />
          }
          Generative AI is quickly being recognized as a tool that increases productivity within nearly every industry. 
          Studies have shown that software developers, customer support representatives, and general business users are seeing significant productivity increases as a result of this technology. 
          When generative AI is used with an organization's data, it can help users find, analyze, and summarize documents, emails, and other data. */}
        </Info>
      </StaggeredFade>
    </Container>
    </>
  )
}
