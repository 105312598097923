import tw from 'twin.macro'

export const Title = tw.h1`
  font-title text-4xl md:text-5xl uppercase flex flex-row
`

export const GradientTitle = tw.div`
  w-max font-title text-4xl md:text-5xl uppercase bg-gradient-to-r! from-cyan-400 to-emerald-400 text-transparent bg-clip-text contrast-200
`

export const Title2 = tw.h2`
  font-title text-4xl font-semibold uppercase
`

export const Title3 = tw.h3`
  text-3xl font-semibold mb-1
`

export const TitleContainer = tw.div`
  flex flex-col items-start justify-start gap-1
`

export const TextWithIconContainer = tw(TitleContainer)`
  flex flex-row items-center
`

export const Subtitle = tw.span`
  font-title text-xs md:text-sm text-neutral-500 font-medium uppercase
`

export const Content = tw.div`
  md:text-lg mb-3 font-normal
`

export const MutedText = tw.span`
  text-neutral-500
`

export const SmallMutedText = tw(MutedText)`
  text-xs
`

export const BigBlue = tw.strong`
  font-bold bg-gradient-to-r! from-cyan-400 to-emerald-400 text-transparent bg-clip-text contrast-200
`

export const List = tw.ul`
  ml-4 list-disc my-4 [&>li]:(mt-2 md:mt-1)
`

export const Email = tw.a`
  font-bold bg-gradient-to-r! from-cyan-400 to-emerald-400 text-transparent bg-clip-text border-b border-cyan-400 contrast-200
`