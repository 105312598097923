import { motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import tw from 'twin.macro'
import Wordmark from './Logo'

const Bar = styled(motion.div)(({ $contactVisible }) => [
  tw`
    z-50
    fixed top-0 left-0 right-0 h-20 py-8 px-4 md:px-20
    flex flex-row items-center justify-start
    bg-black/40 backdrop-blur-md
    transition-transform
  `,
  $contactVisible && tw`justify-between`
])

const WordmarkContainer = tw(motion(Link))`
  h-8 md:h-10 hover:fill-neutral-300 
`

const StyledWordmark = tw(Wordmark)`
  h-full
`

const ContactUs = tw(motion.a)`
  flex flex-row font-title uppercase tracking-tight leading-tight-extreme cursor-pointer text-right
  group-hover:text-cyan-500
`

const Arrow = tw.div`
  pl-1 md:pl-3 group-hover:(translate-x-2 text-cyan-500) transition-all
`

export default function TopBar({ visible }) {

  return (
    <Bar $contactVisible={visible}>
      <WordmarkContainer layout 
        style={{
          marginLeft: visible ? 0 : 'auto',
          marginRight: visible ? 0 : 'auto'
        }}
        initial={{
          opacity: 0
        }}
        animate={{
          opacity: 1
        }}
        transition={{
          delay: 2,
          layout: { delay: 0 }
        }}
        to="/"
      >
        <StyledWordmark />
      </WordmarkContainer>
      {
        visible
        ? <ContactUs layout className="group"
          href="#contact"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <span>Contact Us</span>
          <Arrow>→</Arrow>
        </ContactUs>
        : <></>
      }
    </Bar>
  )
}
