import { motion } from "framer-motion";
import React from 'react';

const DEFAULT_TRANSITION = {
  duration: 15,
  ease: "easeInOut"
}

function DrawablePath ({initial, animate, transition=DEFAULT_TRANSITION, children, ...others}) {
  return (
    <motion.path 
      initial={{ ...initial, pathLength: 1, pathOffset: 0 }}
      animate={{ ...animate, pathLength: 1, pathOffset: 1 }}
      transition={transition}
      {...others}
    >
      {children}
    </motion.path>
  )
}

export default function SAIKAnimated(props) {
  return (
    <motion.svg id="Layer_8" data-name="ANIMATED_SAIK" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1603.5 570">
      <DrawablePath d="m13.21,436.55c0-31.1,25.79-56.88,56.88-56.88,22.75,0,36.41,12.14,46.27,25.79,15.17,20.48,26.55,46.27,67.5,46.27,34.13,0,60.68-26.55,60.68-60.68s-31.86-47.78-71.3-60.68l-38.68-12.89c-57.64-18.96-117.56-54.61-117.56-154.73C17,77.04,90.57,11.81,183.86,11.81c100.12,0,162.31,72.81,162.31,113.01,0,31.1-25.79,56.88-56.89,56.88-22,0-33.37-10.62-43.99-23.51-14.41-18.2-26.55-40.2-61.44-40.2-29.58,0-53.09,23.51-53.09,52.33,0,31.1,28.82,46.27,59.92,56.13l32.61,10.62c63.71,20.48,135.01,51.58,135.01,161.55,0,91.02-76.61,159.28-174.45,159.28S13.21,483.58,13.21,436.55Z"/>
      <DrawablePath d="m743.61,214.32l-272.29,324.62c-9.1,10.62-26.55,18.96-40.96,18.96-29.58,0-53.09-23.51-53.09-53.09,0-11.38,2.28-22,12.13-34.13L754.98,34.56c13.65-16.69,30.34-22.75,45.51-22.75,31.1,0,56.88,25.79,56.88,56.88v432.33c0,31.1-25.79,56.89-56.88,56.89s-56.89-25.79-56.89-56.89V214.32Z"/>
      <DrawablePath d="m929.42,68.69c0-31.1,25.79-56.88,56.89-56.88s56.88,25.79,56.88,56.88v432.33c0,31.1-25.79,56.89-56.88,56.89s-56.89-25.79-56.89-56.89V68.69Z"/>
      <DrawablePath d="m1115.24,68.69c0-31.1,25.79-56.88,56.89-56.88s56.88,25.79,56.88,56.88v432.33c0,31.1-25.79,56.89-56.88,56.89s-56.89-25.79-56.89-56.89V68.69Zm141.83,251.05c-6.07-7.58-12.89-18.96-12.89-34.89,0-14.41,5.31-25.79,12.13-34.13l184.31-219.96c9.86-12.14,25.79-18.96,40.96-18.96,29.58,0,53.09,23.51,53.09,53.09,0,14.41-4.55,25.03-12.89,34.89l-150.18,179,155.49,185.83c9.86,12.14,12.89,24.27,12.89,37.17,0,31.86-25.03,56.13-56.13,56.13-16.69,0-32.61-6.83-43.99-20.48l-182.79-217.68Z"/>
      <circle cx="1540.05" cy="278.63" r="51"/>
      <circle cx="669.05" cy="429.63" r="46"/>
    </motion.svg>
  )
}
