import { motion, stagger, useAnimate, useInView } from 'framer-motion'
import React, { useEffect } from 'react'
import { css } from 'styled-components'
import tw from 'twin.macro'

export const Container = tw.div`
  h-full w-full max-w-lg
`

export default function Section1(props) {
  const [scope, animate] = useAnimate()
  const inView = useInView(scope, { amount: "all", once: true })

  useEffect(() => {
    if (inView) {
      animate("svg path", {
        opacity: [0, 1],
        scale: [0.6, 1],
        translateY: [100, 0]
      }, {
        delay: stagger(0.3, {
          startDelay: 0.5
        })
      })
    }
  }, [inView, animate])

  return (
    <Container ref={scope}>
      <motion.svg id="Section1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2447 938">
        <defs>
          <style>
            {css`
              #Section1 .cls-1 {
                fill: url(#linear-gradient);
              }
    
              #Section1 .cls-2 {
                fill: #404040;
              }
            `}
          </style>
          <linearGradient id="linear-gradient" x1="1207" y1="336.5" x2="2291.46" y2="336.5" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#22d3ee"/>
            <stop offset="1" stopColor="#34d399"/>
          </linearGradient>
        </defs>
        <rect x="0" width="2459" height="942"/>
        <motion.path opacity="0" className="cls-1" d="m2174.46,163h-850.46c-64.62,0-117,52.38-117,117h0c0,64.62,52.38,117,117,117h723.46c14.13,54.8,58.1,86.48,116,113-42.85-48.32-52.58-92.61,0-113h11c64.62,0,117-52.38,117-117h0c0-64.62-52.38-117-117-117Z"/>
        <motion.path opacity="0" className="cls-2" d="m1629,510H237c-64.62,0-117,52.38-117,117h0c0,64.62,50.38,117,115,117h1c52.58,20.39,42.85,64.68,0,113,57.9-26.52,101.87-58.2,116-113h1277c64.62,0,117-52.38,117-117h0c0-64.62-52.38-117-117-117Z"/>
      </motion.svg>
    </Container>
  )
}
