import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./components/pages/Home";
import Main from "./components/pages/Main";
import PrivacyPolicy from "./components/pages/Privacy/PrivacyPolicy";

const router = createBrowserRouter([
  {
    path: '*',
    element: <Main />,
    children: [
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />
      },
      {
        path: '*',
        element: <Home />
      }
    ]
  }
])

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
