import React from 'react'
import { createGlobalStyle } from 'styled-components'
import tw, { GlobalStyles as BaseStyles } from 'twin.macro'

const CustomStyles = createGlobalStyle`
  @keyframes fadeIn {
    from {
      opacity: 0;
      filter: saturate(50%);
    }

    to {
      opacity: 1;
      filter: saturate(300%);
    }
  };

  #root {
    w-full h-full
  }

  body {
    ${tw`
      antialiased
      bg-black
      text-white
      w-[100vw]
      overflow-x-hidden
      // font-mono
      snap-center
      selection:(
        bg-emerald-400 text-black
      )
    `}
  };

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
  }

  .bubbleCanvas {
    animation: 10s fadeIn forwards;
  };
`

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
)

export default GlobalStyles