import { stagger, useAnimate, useInView } from 'framer-motion'
import React, { useEffect } from 'react'

export default function StaggeredFade({ children }) {
  const [scope, animate] = useAnimate()
  const inView = useInView(scope, { once: true })

  useEffect(() => {
    animate("div, p, svg, li", {
      opacity: [0, 1],
      translateY: [20, 0],
    }, {
      delay: stagger(0.2)
    })
  }, [inView, animate])
  

  return (
    <div ref={scope}>
      {children}
    </div>
  )
}
