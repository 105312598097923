import { motion, stagger, useAnimate, useInView } from 'framer-motion'
import React, { useEffect } from 'react'
import { css } from 'styled-components'
import { Container } from './Section1'

const ANIM_SEQUENCE = [
  [
    ".speechBubble", 
    { 
      opacity: [0, 1],
      scale: [0.6, 1],
      translateY: [100, 0]
    },
    {
      delay: 0.5,
      scale: { type: "spring" }
    }
  ],
  [
    "line",
    {
      opacity: [0, 1],
      pathLength: [0, 1]
    },
    {
      at: 0.7,
      delay: stagger(0.1),
      duration: 0.5
    }
  ],
  [
    ".infoCircle",
    {
      opacity: [0, 1],
      scale: [0.6, 1]
    },
    {
      delay: stagger(0.1),
      at: 1,
      scale: { type: "spring" }
    }
  ]
]

export default function Section2(props) {
  const [scope, animate] = useAnimate()
  const inView = useInView(scope, { amount: "all", once: true })

  useEffect(() => {
    if (inView) {
      animate(ANIM_SEQUENCE)
    }
  }, [inView, animate])

  return (
    <Container ref={scope}>
      <svg id="Section2" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2447 1810">
        <defs>
          <style>
            {
              css`
                #Section2 .cls-1 {
                  fill: #404040;
                }
      
                #Section2 .cls-1, .speechBubble {
                  stroke-width: 20px;
                }
      
                #Section2 .cls-1, .speechBubble, .cls-3, .cls-4 {
                  stroke-miterlimit: 10;
                }
      
                #Section2 .cls-1, .speechBubble, .cls-4 {
                  stroke: #000;
                }
      
                #Section2 .speechBubble {
                  fill: url(#linear-gradient);
                }
      
                #Section2 .cls-3 {
                  fill: none;
                  stroke: #404040;
                  stroke-width: 35px;
                  stroke-linecap: round;
                }
      
                #Section2 .cls-4, .cls-5 {
                  fill: #f2f2f2;
                } 
              `
            }
          </style>
          <linearGradient id="linear-gradient" x1="1207" y1="1573.5" x2="2291.46" y2="1573.5" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#22d3ee"/>
            <stop offset="1" stop-color="#34d399"/>
          </linearGradient>
        </defs>
        <g>
          <line opacity="0" className="cls-3" x1="1377" y1="1533" x2="487" y2="1168"/>
          <line opacity="0" className="cls-3" x1="1376.5" y1="1532.5" x2="1025.5" y2="471.5"/>
          <line opacity="0" className="cls-3" x1="1376.5" y1="1532.5" x2="1774.5" y2="967.5"/>
        </g>
        <motion.path className="speechBubble" opacity="0" d="m2174.46,1400h-850.46c-64.62,0-117,52.38-117,117h0c0,64.62,52.38,117,117,117h723.46c14.13,54.8,58.1,86.48,116,113-42.85-48.32-52.58-92.61,0-113h11c64.62,0,117-52.38,117-117h0c0-64.62-52.38-117-117-117Z"/>
        <motion.g className="infoCircle" opacity="0">
          <circle className="cls-1" cx="487.5" cy="1167.5" r="199.5"/>
          <path className="cls-4" d="m450.5,1126.57c5.5-1.72,34.02-8.94,60.83-10.65v71.14c0,39.18.34,57.39.69,64.27l23.71,8.94-3.09,13.75c-14.43,0-28.87-.34-43.99-.34-13.75,0-28.18.34-42.61.34l3.09-13.75,17.87-8.94c0-9.97.69-37.12.69-64.95v-39.52l-19.93-8.94,2.75-11.34Zm39.52-84.54c6.19,2.06,18.9,16.15,23.37,25.09l-.69,4.12c-4.12,8.25-17.53,21.31-23.71,25.09l-3.78,1.03c-8.59-4.81-19.25-16.84-23.71-25.43l1.03-3.78c6.19-9.97,17.18-20.96,24.4-25.43l3.09-.69Z"/>
        </motion.g>
        <motion.g className="infoCircle" opacity="0">
          <circle className="cls-1" cx="1772" cy="968" r="124"/>
          <path className="cls-5" d="m1749,942.56c3.42-1.07,21.15-5.55,37.81-6.62v44.22c0,24.35.21,35.67.43,39.94l14.74,5.55-1.92,8.54c-8.97,0-17.94-.21-27.34-.21-8.54,0-17.52.21-26.49.21l1.92-8.54,11.11-5.55c0-6.19.43-23.07.43-40.37v-24.56l-12.39-5.55,1.71-7.05Zm24.56-52.55c3.85,1.28,11.75,10.04,14.53,15.59l-.43,2.56c-2.56,5.13-10.89,13.24-14.74,15.59l-2.35.64c-5.34-2.99-11.96-10.47-14.74-15.81l.64-2.35c3.85-6.19,10.68-13.03,15.17-15.81l1.92-.43Z"/>
        </motion.g>
        <motion.g className="infoCircle" opacity="0">
          <circle className="cls-1" cx="1026" cy="472" r="351"/>
          <path className="cls-4" d="m960.9,399.98c9.67-3.02,59.86-15.72,107.02-18.74v125.16c0,68.93.61,100.98,1.21,113.07l41.72,15.72-5.44,24.19c-25.39,0-50.79-.61-77.39-.61-24.19,0-49.58.61-74.98.61l5.44-24.19,31.44-15.72c0-17.53,1.21-65.3,1.21-114.28v-69.53l-35.07-15.72,4.84-19.95Zm69.53-148.74c10.88,3.63,33.26,28.42,41.12,44.14l-1.21,7.26c-7.26,14.51-30.84,37.49-41.72,44.14l-6.65,1.81c-15.12-8.46-33.86-29.63-41.72-44.74l1.81-6.65c10.88-17.53,30.23-36.88,42.93-44.74l5.44-1.21Z"/>
        </motion.g>
      </svg>
    </Container>
  )
}
