import React from 'react'
import { Link } from 'react-router-dom'
import tw from 'twin.macro'
import Wordmark from './Logo'

const Container = tw.div`
  w-full py-60 relative
  flex flex-col items-center justify-around
  bg-gradient-to-tr from-cyan-400 to-emerald-400 saturate-200
  text-neutral-900 text-sm
`

const Content = tw.div`
  flex flex-col items-center justify-around px-4 py-5 rounded-md gap-1
`

const StyledWordmark = tw(Wordmark)`
  h-10 fill-black
`

const Links = tw.div`
  flex flex-col items-center gap-1 mt-3
`

const FooterLink = tw(Link)`
  text-neutral-900 font-semibold
  [&>span]:(
    px-1 py-0.5 rounded bg-transparent transition-colors
    hover:(bg-neutral-800/10 text-black)
  )
`

export default function Footer(props) {
  

  return (
    <Container>
      <Content>
        <StyledWordmark />
        <span>© SAIK Inc. 2023. All rights reserved.</span>
        <Links>
          <FooterLink to="/"><span>Home</span></FooterLink>
          <FooterLink to="/privacy-policy"><span>Privacy Policy</span></FooterLink>
        </Links>
      </Content>
    </Container>
  )
}
