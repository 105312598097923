import { motion, stagger, useAnimate, useInView } from 'framer-motion'
import { MoveDown, MoveRight } from 'lucide-react'
import React, { useEffect } from 'react'
import tw from 'twin.macro'
import DrawingCircle from '../common/DrawingCircle'

const Circle = tw(motion.div)`
  absolute top-0 left-0 right-0 bottom-0 m-auto mix-blend-difference z-40 pointer-events-none h-40 rotate-[4deg] -translate-y-1 -translate-x-2 md:-translate-x-3 transition-all
`

const ContactContainer = tw.div`
  xl:grow-[1] flex items-center justify-center
`

const ContactUs = tw(motion.a)`
  flex flex-row font-title uppercase text-xl lg:text-3xl tracking-tight cursor-pointer shrink-0 py-10
`

const ContactText = tw.span`
  group-hover:(text-emerald-400) transition-colors saturate-200
`

const RightArrow = tw(MoveRight)`
  ml-2 -mt-1 md:mt-0 md:ml-5 group-hover:(translate-x-4 text-emerald-400) saturate-200 transition-all w-8 h-8
`

const SecureAI = tw.div`
  flex flex-col grow-0
  font-title text-5xl md:text-6xl lg:text-7xl xl:text-9xl tracking-tighter leading-tight-extreme! text-right uppercase select-none
  w-min
  bg-gradient-to-r! from-cyan-400 to-emerald-400 text-transparent bg-clip-text contrast-200
  [&>span]:(mt-2)
`

const Container = tw.div`
  flex flex-col w-full h-[100vh] px-4 lg:px-20 items-start justify-center overflow-x-hidden
`

const Row = tw.div`
  flex flex-row flex-wrap gap-4 md:gap-8 xl:gap-10 py-20
  overflow-visible items-center justify-around xl:justify-between w-full h-full
`

const ScrollDownContainer = tw(motion.div)`
  absolute bottom-0 left-0 right-0 mx-auto mb-4 md:mb-8 w-max
  flex flex-col gap-2 items-center
  text-xs xl:text-sm font-semibold uppercase
`

const ScrollDown = tw(MoveDown)`
  h-5 w-5 md:(h-8 w-8)
`

export default function Lander({ innerRef }) {
  const [secureAIScope, secureAIAnimate] = useAnimate()
  const secureAIInView = useInView(secureAIScope)

  useEffect(() => {
    if (secureAIInView) {
      secureAIAnimate("span", {
        opacity: [0, 1],
        translateX: [100, 0]
      }, {
        delay: stagger(0.2)
      })
    }
  }, [secureAIInView, secureAIAnimate])

  return (
    <Container>
      {/* <BlurryBackground /> */}
      <Row ref={innerRef}>
        <SecureAI ref={secureAIScope}>
          <span style={{opacity: 0}}>SECURE</span>
          <span style={{opacity: 0}}>ARTIFICIAL</span>
          <span style={{opacity: 0}}>INTELLIGENCE</span>
        </SecureAI>
        <ContactContainer>
          <ContactUs className="group"
            href="#contact"
            initial={{ opacity: 0, translateX: -100 }}
            animate={{ opacity: 1, translateX: 1 }}
            transition={{ delay: 1 }}
          >
            <Circle initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <DrawingCircle />
            </Circle>
            <ContactText>Contact Us</ContactText>
            <RightArrow />
          </ContactUs>
        </ContactContainer>
      </Row>
      <ScrollDownContainer
        initial={{ opacity: 0, translateY: -100 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{
          delay: 1.5
        }}
      >
        <span>Learn More</span>
        <ScrollDown />
      </ScrollDownContainer>
    </Container>
  )
}
