import React from 'react'
import tw from 'twin.macro'
// import { ReactComponent as Section1 } from '../../svg/sections/section-01.svg'
// import { ReactComponent as Section2 } from '../../svg/sections/section-02.svg'
// import { ReactComponent as Section3 } from '../../svg/sections/section-03.svg'
import Section1 from '../animations/sections/Section1'
import Section2 from '../animations/sections/Section2'
import Section3 from '../animations/sections/Section3'
import FadeInTitle from '../common/FadeInTitle'
import StaggeredFade from '../common/StaggeredFade'
import { BigBlue, Content } from '../common/Typography'

const Container = tw.div`
  mx-5
  sm:mx-10
  lg:mx-[220px]
  2xl:even:flex-row-reverse
`

const Section = tw.section`
  mb-40 flex flex-col 2xl:(flex-row) items-center justify-center gap-4 md:gap-10 saturate-200
`

const SectionContent = tw.div`
  max-w-3xl
`

const SectionImage = tw.div`
  max-w-lg
`

export default function Features(props) {
  

  return (
    <Container>
        <Section>
          <SectionContent>
            <FadeInTitle content={'You already know how to use SAIK.'} />
            <StaggeredFade>
              <Content><BigBlue>SAIK provides a simple message-based interface, just like any messaging app.</BigBlue> Just ask a question and SAIK can help you write formulas in spreadsheets, figure out the right wording for a document you're writing, or find the document you're looking for.</Content>
            </StaggeredFade>
          </SectionContent>
          <SectionImage as={Section1} />
        </Section>
        <Section>
          <SectionContent>
            <FadeInTitle content={'Manage SAIK\'s knowledge directly.'} />
            <StaggeredFade>
              <Content><BigBlue>SAIK integrates with an organization's data and documents at an enterprise level.</BigBlue> When it is asked a question or given instructions, it quickly finds relevant information and uses that information to respond to the user.</Content>
              <Content>This capability goes far beyond anything possible with a text search, using information found in an organization's knowledge base to respond to the user's prompt in plain language.</Content>
            </StaggeredFade>
          </SectionContent>
          <SectionImage as={Section2} />
        </Section>
        <Section>
          <SectionContent>
            <FadeInTitle content={'Never let your data leave.'} />
            <StaggeredFade>
              <Content><BigBlue>SAIK is designed around security first.</BigBlue> Our team has over 10 years of experience developing software for use in sensitive and classified DoD environments, and we have applied that experience when building SAIK.</Content>
              <Content>SAIK provides controls for who can see what information within an organization. For example, Human Resources may have access to different sensitive data than the IT department. With SAIK, an organization can control the information the AI uses and presents to the user through access controls.</Content>
            </StaggeredFade>
          </SectionContent>
          <SectionImage as={Section3} />
        </Section>
    </Container>
  )
}
