import { stagger, useAnimate, useInView } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import underline from '../../img/Underline.png'
import { Title } from './Typography'

const Word = tw.div`
  select-none mr-3 relative opacity-0
`

const Container = tw(Title)`
  flex-wrap
`

const Underline = tw.img`
  absolute w-60 rotate-[9deg] transform! left-0 right-0 top-[16px] -hue-rotate-15 saturate-200
`

export default function FadeInTitle({ content, children }) {
  const [words, setWords] = useState(content.trim().split(/\s+/))
  const [scope, animate] = useAnimate()
  const inView = useInView(scope, { amount: "some", once: true })

  useEffect(() => {
    setWords(content.trim().split(/\s+/))
  }, [content])

  useEffect(() => {
    animate("*", {
      opacity: [0, 1],
      translateY: [20, 0],
    }, {
      delay: stagger(0.1)
    })
  }, [inView, animate])

  return (
    <Container ref={scope}>
      {words?.map((word, index) => {
        let isUnderline = false
        if (word.startsWith('!!!')) {
          word = word.replace('!!!', '')
          isUnderline = true
        }

        return (
          <Word key={index} style={{ opacity: 0 }}>
            {word}
            {
              isUnderline &&
              <Underline src={underline} />
            }
          </Word>
        )
      })}
      {children}
    </Container>
  )
}
