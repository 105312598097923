import { motion, stagger, useAnimate, useInView } from 'framer-motion'
import React, { useEffect } from 'react'
import { css } from 'styled-components'
import { Container } from './Section1'

const ANIM_SEQUENCE = [
  [
    ".infoCircle",
    {
      opacity: [0, 1],
      scale: [0.6, 1]
    },
    {
      scale: { type: "spring" }
    }
  ],
  [
    ".grayGuy",
    {
      opacity: [0, 1],
      scale: [0.6, 1]
    },
    {
      at: '-0.7',
      delay: stagger(0.1),
      scale: { type: "spring" }
    }
  ],
  [
    ".grayConnector",
    {
      opacity: [0, 1],
      pathLength: [0, 1]
    },
    {
      at: '-0.5',
      delay: stagger(0.1),
      duration: 0.5
    }
  ],
  [
    ".lock",
    {
      opacity: [0, 1],
      scale: [0.6, 1]
    },
    {
      at: '-0.4',
      delay: stagger(0.1),
      scale: { type: "spring" }
    }
  ],
  [
    ".gradientGuy",
    {
      opacity: [0, 1],
      scale: [0.6, 1]
    },
    {
      at: '-0.3',
      scale: { type: "spring" }
    }
  ],
  [
    ".gradientConnector",
    {
      opacity: [0, 1]
    },
    {
      at: '-0.5',
      duration: 0.5
    }
  ],
]

export default function Section3(props) {
  const [scope, animate] = useAnimate()
  const inView = useInView(scope, { amount: "all", once: true })

  useEffect(() => {
    if (inView) {
      animate(ANIM_SEQUENCE)
    }
  }, [inView, animate])

  return (
    <Container ref={scope}>
      <motion.svg id="Section3" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 2447 1810">
        <defs>
          <style>
            {css`
              #Section3 .cls-1, .cls-2, .cls-3 {
                stroke: #000;
              }
  
              #Section3 .cls-1, .cls-2, .cls-3, .cls-4 {
                stroke-miterlimit: 10;
              }
  
              #Section3 .cls-1, .cls-5 {
                stroke-width: 20px;
              }
  
              #Section3 .cls-1, .cls-6 {
                fill: #404040;
              }
  
              #Section3 .cls-7 {
                fill: url(#linear-gradient-2);
              }
  
              #Section3 .cls-2 {
                stroke-width: 50px;
              }
  
              #Section3 .cls-5 {
                stroke-linejoin: round;
              }
  
              #Section3 .cls-5, .cls-4 {
                fill: none;
                stroke: #404040;
                stroke-linecap: round;
              }
  
              #Section3 .cls-8 {
                fill: url(#linear-gradient-3);
              }
  
              #Section3 .cls-9 {
                fill: url(#linear-gradient);
              }
  
              #Section3 .cls-3 {
                fill: #f2f2f2;
              }
  
              #Section3 .cls-4 {
                stroke-width: 35px;
              }
            `}
          </style>
          <linearGradient id="linear-gradient" x1="1209.4" y1="835.22" x2="1977" y2="835.22" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#22d3ee"/>
            <stop offset="1" stop-color="#34d399"/>
          </linearGradient>
          <linearGradient id="linear-gradient-2" x1="1888.78" y1="1538.41" x2="2037.19" y2="1605.13" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#22d3ee"/>
            <stop offset="1" stop-color="#34d399"/>
          </linearGradient>
          <linearGradient id="linear-gradient-3" x1="1918.52" y1="1432.59" x2="2011.26" y2="1473.96" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#22d3ee"/>
            <stop offset="1" stop-color="#34d399"/>
          </linearGradient>
        </defs>
        <rect x="-3" y="-7" width="2459" height="1828"/>
        <motion.line className="cls-4 grayConnector" opacity="0" x1="1226.5" y1="392.5" x2="1226.5" y2="1281.5" />
        <motion.path className="cls-4 grayConnector" opacity="0" d="m1227.1,392.95l-700.35,609.36c-19.86,17.28-31.26,42.31-31.26,68.63v206.57"/>
        <motion.path className="cls-9 gradientConnector" opacity="0" d="m1959.5,1295c-9.67,0-17.5-7.83-17.5-17.5v-206.89c0-21.06-9.12-41.05-25.01-54.87l-701.57-609.59c-7.29-6.34-8.07-17.39-1.73-24.69,6.34-7.29,17.39-8.07,24.69-1.73l701.57,609.59c23.55,20.46,37.05,50.09,37.05,81.29v206.89c0,9.67-7.83,17.5-17.5,17.5Z"/>
        <motion.g className="infoCircle" opacity="0">
          <circle className="cls-1" cx="1223.5" cy="392.5" r="199.5"/>
          <path className="cls-3" d="m1186.5,351.57c5.5-1.72,34.02-8.94,60.83-10.65v71.14c0,39.18.34,57.39.69,64.27l23.71,8.94-3.09,13.75c-14.43,0-28.87-.34-43.99-.34-13.75,0-28.18.34-42.61.34l3.09-13.75,17.87-8.94c0-9.97.69-37.12.69-64.95v-39.52l-19.93-8.94,2.75-11.34Zm39.52-84.54c6.19,2.06,18.9,16.15,23.37,25.09l-.69,4.12c-4.12,8.25-17.53,21.31-23.71,25.09l-3.78,1.03c-8.59-4.81-19.25-16.84-23.71-25.43l1.03-3.78c6.19-9.97,17.18-20.96,24.4-25.43l3.09-.69Z"/>
        </motion.g>
        <motion.g className="grayGuy" opacity="0">
          <path className="cls-5" d="m566.51,1593.43v-20.39c0-22.52-18.26-40.77-40.77-40.77h-61.16c-22.52,0-40.77,18.26-40.77,40.77v20.39"/>
          <circle className="cls-5" cx="495.16" cy="1450.72" r="40.77"/>
        </motion.g>
        <motion.g className="grayGuy" opacity="0">
          <path className="cls-5" d="m1298.51,1593.43v-20.39c0-22.52-18.26-40.77-40.77-40.77h-61.16c-22.52,0-40.77,18.26-40.77,40.77v20.39"/>
          <circle className="cls-5" cx="1227.16" cy="1450.72" r="40.77"/>
        </motion.g>
        <motion.g className="gradientGuy" opacity="0">
          <path className="cls-7" d="m2030.51,1603.43c-5.52,0-10-4.48-10-10v-20.39c0-16.97-13.81-30.77-30.77-30.77h-61.16c-16.97,0-30.77,13.81-30.77,30.77v20.39c0,5.52-4.48,10-10,10s-10-4.48-10-10v-20.39c0-28,22.78-50.77,50.77-50.77h61.16c28,0,50.77,22.78,50.77,50.77v20.39c0,5.52-4.48,10-10,10Z"/>
          <path className="cls-8" d="m1959.16,1501.49c-28,0-50.77-22.78-50.77-50.77s22.78-50.77,50.77-50.77,50.77,22.78,50.77,50.77-22.78,50.77-50.77,50.77Zm0-81.55c-16.97,0-30.77,13.81-30.77,30.77s13.81,30.77,30.77,30.77,30.77-13.81,30.77-30.77-13.81-30.77-30.77-30.77Z"/>
        </motion.g>
        <motion.g className="lock" opacity="0">
          <path className="cls-2" d="m798.6,777.84h-3.77v-17.55c0-24.5-19.93-44.43-44.43-44.43s-44.43,19.93-44.43,44.43v17.55h-3.77c-13.11,0-23.77,10.67-23.77,23.77v48.21c0,13.11,10.67,23.77,23.77,23.77h96.41c13.11,0,23.77-10.67,23.77-23.77v-48.21c0-13.11-10.67-23.77-23.77-23.77Z"/>
          <path className="cls-6" d="m798.6,777.84h-3.77v-17.55c0-24.5-19.93-44.43-44.43-44.43s-44.43,19.93-44.43,44.43v17.55h-3.77c-13.11,0-23.77,10.67-23.77,23.77v48.21c0,13.11,10.67,23.77,23.77,23.77h96.41c13.11,0,23.77-10.67,23.77-23.77v-48.21c0-13.11-10.67-23.77-23.77-23.77Zm-72.64-17.55c0-13.47,10.96-24.43,24.43-24.43s24.43,10.96,24.43,24.43v17.55h-48.87v-17.55Zm76.41,89.53c0,2.08-1.69,3.77-3.77,3.77h-96.41c-2.08,0-3.77-1.69-3.77-3.77v-48.21c0-2.08,1.69-3.77,3.77-3.77h96.41c2.08,0,3.77,1.69,3.77,3.77v48.21Z"/>
        </motion.g>
        <motion.g className="lock" opacity="0">
          <path className="cls-2" d="m1271.6,867.84h-3.77v-17.55c0-24.5-19.93-44.43-44.43-44.43s-44.43,19.93-44.43,44.43v17.55h-3.77c-13.11,0-23.77,10.67-23.77,23.77v48.21c0,13.11,10.67,23.77,23.77,23.77h96.41c13.11,0,23.77-10.67,23.77-23.77v-48.21c0-13.11-10.67-23.77-23.77-23.77Z"/>
          <path className="cls-6" d="m1271.6,867.84h-3.77v-17.55c0-24.5-19.93-44.43-44.43-44.43s-44.43,19.93-44.43,44.43v17.55h-3.77c-13.11,0-23.77,10.67-23.77,23.77v48.21c0,13.11,10.67,23.77,23.77,23.77h96.41c13.11,0,23.77-10.67,23.77-23.77v-48.21c0-13.11-10.67-23.77-23.77-23.77Zm-72.64-17.55c0-13.47,10.96-24.43,24.43-24.43s24.43,10.96,24.43,24.43v17.55h-48.87v-17.55Zm76.41,89.53c0,2.08-1.69,3.77-3.77,3.77h-96.41c-2.08,0-3.77-1.69-3.77-3.77v-48.21c0-2.08,1.69-3.77,3.77-3.77h96.41c2.08,0,3.77,1.69,3.77,3.77v48.21Z"/>
        </motion.g>
      </motion.svg>
    </Container>
  )
}
