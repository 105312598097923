import { useInView } from "framer-motion";
import { useRef } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import Footer from "../common/Footer";
import TopBar from "../common/TopBar";

export const MOBILE_BREAKPOINT = 768

export default function Main(props) {
  const landerRef = useRef()
  const landerInView = useInView(landerRef)

  return (
    <>
      <TopBar visible={!landerInView} />
      <Outlet context={landerRef} />
      <Footer />
      <ScrollRestoration />
    </>
    
  )
}
