import React from 'react'
import { useOutletContext } from 'react-router-dom'
import Adjectives from './Adjectives'
import Contact from './Contact'
import Features from './Features'
import Lander from './Lander'
import WhatIsSAIK from './WhatIsSAIK'

export default function Home(props) {
  const landerRef = useOutletContext()

  return (
    <>
      <Lander innerRef={landerRef} />
      <WhatIsSAIK />
      <Adjectives />
      <Features />
      <Contact />
    </>
  )
}
